import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentSingleTabManager,
  Firestore
} from 'firebase/firestore';
import { firebaseConfig, FirebaseEnvironment } from './config';

let app: FirebaseApp | null = null;
let db: Firestore | null = null;

export function initializeFirebase(env: FirebaseEnvironment = 'production') {
  if (app) {
    return { app, db };
  }

  console.log(`Initializing Firebase in ${env} mode`);
  app = initializeApp(firebaseConfig[env]);
  
  db = initializeFirestore(app, {
    localCache: persistentLocalCache({
      tabManager: persistentSingleTabManager({})
    })
  });

  return { app, db };
}

export * from './config'; 