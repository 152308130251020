import { ref, computed } from "vue";
import { useErrorLog } from "./errorLog";

const isProcessing = ref(false);
const isNotDesktop = ref(true);
const isMobile = computed(
  () => window.matchMedia("(any-pointer:coarse)").matches && isNotDesktop.value,
);

export const useSystemState = () => {
  function changeDeviceType() {
    isNotDesktop.value = !isNotDesktop.value;
  }

  function startProcessing() {
    isProcessing.value = true;
    const { setWarning } = useErrorLog();
    setTimeout(() => {
      if (isProcessing.value) {
        setWarning(
          "This took longer than expected. Any changes you may have made, may not be saved. Check your internet connection.",
        );
        isProcessing.value = false;
      }
    }, 5000);
  }

  function stopProcessing() {
    isProcessing.value = false;
  }

  return {
    isProcessing,
    isMobile,
    isNotDesktop,
    changeDeviceType,
    startProcessing,
    stopProcessing,
  };
};
