<template>
  <SystemErrorBoundary>
    <teleport to="body">
      <div
        v-if="isProcessing"
        class="flex justify-center items-center z-50 fixed top-0 w-full h-full bg-green-darkest"
      >
        <div class="flex flex-col justify-center items-center">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
          <p class="text-white mt-2 font-semibold text-lg">
            {{ t("loader.text") }}
          </p>
        </div>
      </div>
    </teleport>
  </SystemErrorBoundary>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useSystemState } from "../composables/systemState";

import SystemErrorBoundary from "./SystemErrorBoundary.vue";

const { t } = useI18n({
  useScope: "local",
});
const { isProcessing } = useSystemState();
</script>

<style scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #e6ffff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "loader": {
      "text": "Processing..."
    }
  },
  "dk": {
    "loader": {
      "text": "Behandler..."
    }
  }
}
</i18n>
