import { createApp } from "vue";

import appComponent from "./App.vue";
import router from "./router";

import "./assets/tailwind.css";
import { fbConnect } from "./plugins/firebase";

import { createI18n } from "vue-i18n";

import { createPinia } from "pinia";

const mode = "__MODE__";
console.log("App environment - " + mode);

fbConnect(mode);

console.log("App status - Creating app");
const app = createApp(appComponent);

console.log("App status - Creating Pinia store");
app.use(createPinia());

console.log("App status - Creating translations");
const i18n = createI18n({
  mode: "composition",
  silentTranslationWarn: true,
});
app.use(i18n);

console.log("App status - Starting router");
app.use(router);

console.log("App status - Mounting app");

app.mount("#app");
