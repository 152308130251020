<template>
  <slot v-if="error" name="error" :err="error" :info="infoMessage"
    ><div
      class="w-full h-full flex flex-col items-center justify-start relative mt-3"
    >
      <span class="text-green text-xl">
        <BaseIcon icon="fa-exclamation-circle" />
      </span>
      <p class="text-yellow mb-3">Åh nej, noget gik galt!</p>
      <p class="text-green text-center">
        Vi beklager ulejligheden, fejlen er registreret, og vi håber at have
        løst problemet hurtigst muligt.
      </p>
    </div></slot
  >
  <slot v-else></slot>
</template>

<script setup>
import { onErrorCaptured, ref } from "vue";
import { useErrorLog } from "../composables/errorLog";
import BaseIcon from "./BaseIcon.vue";

const { logError } = useErrorLog();

const error = ref(false);
const infoMessage = ref(null);

onErrorCaptured((err, vm, info) => {
  error.value = err;
  infoMessage.value = info;
  logError(err, vm.$options.name, vm.$.type.name, info);
  console.log(err.message, vm.$options.name, vm.$.type.name, info);
  console.log(err.stack);

  return false;
});
</script>

<style lang="scss"></style>
