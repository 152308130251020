<script setup lang="ts">
import { 
  Home,
  Settings,
  Calendar,
  User,
  UserPlus,
  Building,
  Plus,
  Play,
  Check,
  X,
  Trash2,
  ChevronLeft,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Clock,
  Coffee,
  FileText,
  LogOut,
  Key,
  AlertCircle,
  Warehouse,
  Mail,
  Heart,
  BarChart3,
  Umbrella,
  Ban,
  Smartphone,
  Undo,
  CheckSquare,
  ClipboardList,
  Bell,
  Square,
  FileType,
  Eye,
  Edit,
  Info,
  Book,
  Tractor,
  TestTube,
  ArrowLeftRight,
  History,
  AlertTriangle,
  DollarSign,
  HelpCircle,
  CircleHelp
} from 'lucide-vue-next'

// Map of old FontAwesome icons to Lucide components
const iconMap = {
  'fa-home': Home,
  'fa-cogs': Settings,
  'fa-calendar-alt': Calendar,
  'fa-user': User,
  'fa-user-plus': UserPlus,
  'fa-building': Building,
  'fa-plus': Plus,
  'fa-play': Play,
  'fa-check': Check,
  'fa-times': X,
  'fa-trash-alt': Trash2,
  'fa-chevron-left': ChevronLeft,
  'fa-chevron-down': ChevronDown,
  'fa-chevron-right': ChevronRight,
  'fa-angle-up': ChevronUp,
  'fa-angle-down': ChevronDown,
  'fa-angle-right': ChevronRight,
  'fa-angle-left': ChevronLeft,
  'fa-clock': Clock,
  'fa-coffee': Coffee,
  'fa-file-alt': FileText,
  'fa-sign-out-alt': LogOut,
  'fa-key': Key,
  'fa-exclamation-circle': AlertCircle,
  'fa-warehouse': Warehouse,
  'fa-envelope-open-text': Mail,
  'fa-hand-holding-heart': Heart,
  'fa-chart-bar': BarChart3,
  'fa-umbrella-beach': Umbrella,
  'fa-ban': Ban,
  'fa-mobile-alt': Smartphone,
  'fa-undo': Undo,
  'fa-check-square': CheckSquare,
  'fa-clipboard-list': ClipboardList,
  'fa-bell': Bell,
  'fa-stop': Square,
  'fa-file-pdf': FileType,
  'fa-eye': Eye,
  'fa-edit': Edit,
  'fa-info': Info,
  'fa-book': Book,
  'fa-tractor': Tractor,
  'fa-flask': TestTube,
  'fa-exchange-alt': ArrowLeftRight,
  'fa-trash': Trash2,
  'fa-history': History,
  'fa-exclamation': AlertTriangle,
  'fa-hand-holding-usd': DollarSign,
  'fa-undo-alt': Undo,
  'fa-question-circle': HelpCircle,
  'fa-question': CircleHelp
}

const props = defineProps<{
  icon: string;
  size?: number;
  class?: string;
}>()

const iconComponent = iconMap[props.icon] || null
</script>

<template>
  <component 
    :is="iconComponent" 
    v-if="iconComponent"
    :size="size || 24"
    :class="class"
  />
  <span v-else class="text-red-500">Icon '{{ icon }}' not found</span>
</template> 