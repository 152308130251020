import { defineStore } from "pinia";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  signOut,
  signInWithEmailAndPassword,
  updatePassword,
  type User,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import router from "../../../router";
import { useSystemState } from "../../../composables/systemState";
import { useErrorLog } from "../../../composables/errorLog";

interface AuthState {
  email: string;
  password: string;
  isLoggedIn: boolean;
  userToken: User | null;
  permissions: Record<string, any>;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    email: "",
    password: "",
    isLoggedIn: false,
    userToken: null,
    permissions: {},
  }),

  getters: {
    getFarmId(): string | undefined {
      return typeof this.permissions.farm === "string"
        ? this.permissions.farm
        : undefined;
    },
    isAdmin(): boolean | undefined {
      return typeof this.permissions.admin === "boolean"
        ? this.permissions.admin
        : undefined;
    },
    isOwner(): boolean | undefined {
      return typeof this.permissions.owner === "boolean"
        ? this.permissions.owner
        : undefined;
    },
    isLeader(): boolean | undefined {
      return typeof this.permissions.leader === "boolean"
        ? this.permissions.leader
        : undefined;
    },
    isEmployee(): boolean | undefined {
      return !this.isLeader && !this.isOwner;
    },
    hasPlanning(): boolean | undefined {
      return typeof this.permissions.planning === "boolean"
        ? this.permissions.planning
        : undefined;
    },
  },

  actions: {
    async logIn(): Promise<void> {
      return new Promise((resolve, reject) => {
        const { startProcessing, stopProcessing } = useSystemState();
        startProcessing();
        const auth = getAuth();
        return signInWithEmailAndPassword(
          auth,
          this.email.trim(),
          this.password.trim(),
        )
          .then(() => {
            router.push("/");
            stopProcessing();
            resolve();
          })
          .catch((error: Error) => {
            stopProcessing();
            reject(error);
          });
      });
    },

    async logOut(): Promise<void> {
      return new Promise((resolve, reject) => {
        const { startProcessing, stopProcessing } = useSystemState();
        startProcessing();
        const auth = getAuth();
        signOut(auth)
          .then(() => {
            router.push("/auth/sign-in");
            resolve();
            stopProcessing();
          })
          .catch((error) => {
            reject(error);
            stopProcessing();
          });
      });
    },

    async resetPassword(lang: string): Promise<void> {
      return new Promise((resolve, reject) => {
        const { startProcessing, stopProcessing } = useSystemState();
        startProcessing();

        const functions = getFunctions(getApp(), "europe-west6");
        const func = httpsCallable(functions, "v2authResetPassword");
        return func({
          email: this.email,
          lang,
        })
          .then(() => {
            router.push("/auth/reset-password-complete");
            stopProcessing();
            resolve();
          })
          .catch((error) => {
            reject(error);
            stopProcessing();
          });
      });
    },

    async getPermissions(): Promise<void> {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        try {
          auth.currentUser?.getIdTokenResult().then((token) => {
            if (token) {
              this.permissions = token.claims;
              resolve();
            }
          });
        } catch (error) {
          console.error(error);
          reject();
        }
      });
    },

    async changePassword(
      currentPassword: string,
      newPassword: string,
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const { startProcessing, stopProcessing } = useSystemState();
        const { setSuccess, setError } = useErrorLog();
        startProcessing();
        const auth = getAuth();
        if (auth.currentUser && auth.currentUser.email) {
          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            currentPassword.trim(),
          );
          reauthenticateWithCredential(auth.currentUser, credential)
            .then(() => {
              if (auth.currentUser) {
                updatePassword(auth.currentUser, newPassword.trim()).then(
                  () => {
                    setSuccess("Din kode er ændret");
                    stopProcessing();
                    resolve();
                  },
                );
              }
            })
            .catch((e) => {
              setError(e.message);
              reject(e);
              stopProcessing();
            });
        }
      });
    },
  },
});
