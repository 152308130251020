import { initializeFirebase } from '@farmhelp/firebase';
import type { FirebaseEnvironment } from '@farmhelp/firebase';
import { logger } from '../composables/logger';

export function fbConnect(mode: string) {
  logger.firebase("Connecting to Firebase");
  logger.firebase("Current mode:", mode);
  
  // Map mode to environment
  const env: FirebaseEnvironment = 
    mode === 'development' || mode === 'dev' ? 'development' :
    mode === 'demo' ? 'demo' : 
    'production';

  logger.firebase(`Environment - ${env}`);
  const { app, db } = initializeFirebase(env);
  return { app, db };
}





