import { getFunctions, httpsCallable } from "firebase/functions";
import { defineStore } from "pinia";

import { getAuth } from "firebase/auth";
import type { User } from "firebase/auth";

import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  doc,
  DocumentData,
  Timestamp,
} from "firebase/firestore";
import type { Unsubscribe } from "firebase/firestore";

import { useSystemState } from "../composables/systemState";
import { useFirestore } from "../composables/firestore";
import { useErrorLog } from "../composables/errorLog";
import { logger } from "../composables/logger";
import { DateTime, Interval } from "luxon";
import { useAuthStore } from "../modules/auth/store/auth";

interface UserProfileData {
  acceptedPrivacyAndSubscriptionTerms?: boolean; // Checked -
  breakDuration?: number; // Should be moved to employee
  email: string; // Should be removed
  farm: string; // Should be deleted
  firstName: string; // Checked -
  lastName: string; // Checked -
  firstSignIn: boolean; // Checked -
  phone: string; // Checked -
  lang?: string; // Checked -
  selectedUnit: string; // Should be moved to employee
  units: Array<string>; // Checked - Can be deleted
  FCMTokens?: Array<string>; // Checked -
  selectedUnitId?: string;
}

interface UserProfile extends UserProfileData {
  id: string;
  deleted: null | Date;
}

interface FarmData {
  address: string; // Checked -
  city: string; // Checked -
  companyName: string; // Checked -
  cvr: string; // Checked -
  multipleUnits: boolean; // Checked -
  ownerFirstName: string; // Checked -
  ownerId: string; // Checked -
  ownerLastName: string; // Checked -
  ownerPhone: string; // Checked -
  subscriptionDate: Date; // Checked -
  subscriptionStatus: string; // Checked -
  type: string; // Checked -
  wagePeriodEndDate: number; // Checked -
  workDaysPerWeek?: number; // Checked -
  zipcode: string; // Checked -
}

interface Farm extends FarmData {
  id: string;
  deleted: null | Timestamp;
}

export interface UnitData {
  unitName: string; // Checked -
  ownerRegistersTime: boolean; // Checked -
  ownerIsPlanned?: boolean; // Checked -
  allowedConcurrentVacations?: number; // Checked -
  usingIp: boolean; // Checked -
  ipAddress?: string; // Checked -
  usingGps: boolean; // Checked -
  usingGpsLog?: boolean; // Checked -
  gpsBounds1?: Array<number>; // Checked -
  gpsBounds2?: Array<number>; // Checked -
  defaultBreakDuration?: number;
  ignorePortal?: string[];
  showPlannedTime?: boolean;
}

export interface Unit extends UnitData {
  id: string;
  updated?: Timestamp;
  updatedByEid: string;
  deleted: Timestamp;
}

interface EmployeeData {
  uid: string; // Checked -
  leader?: boolean; // Checked -
  email?: string; // Needs to be moved here from users.
  firstName: string; // Checked -
  lastName: string; // Checked -
  phone: string; // Checked -
  hours: Array<Contract>; // Checked -
  settingsAccess?: boolean; // Checked -
  startDate: Timestamp; // Checked -
  units: Array<string>;
}

export interface Employee extends EmployeeData {
  id: string;
  deleted: null | Date;
}

export interface Contract {
  date: number;
  month: number;
  value: number;
  year: number;
}
let userListenerConnection = null as Unsubscribe | null;
let farmListenerConnection = null as Unsubscribe | null;
let unitsListenerConnection = null as Unsubscribe | null;
let employeesListenerConnection = null as Unsubscribe | null;

function findLatestValidContractInPeriod(
  contracts: Contract[],
  period: Interval,
) {
  const contract = contracts.reduce(function (
    prev: Contract,
    current: Contract,
  ) {
    return (prev.month > current.month && prev.year >= current.year) ||
      prev.year > current.year ||
      (period.start!.month < current.month &&
        period.start!.year <= current.year)
      ? prev
      : current;
  });
  return contract;
}

export const useMainStore = defineStore({
  id: "main",
  state: () => ({
    pageTransition: {
      name: "router-view",
      mode: "in-out",
    },
    // user
    user: {} as UserProfile,

    // farm
    userFarm: null as Farm | null,

    // units
    showDataForAllUnits: false,
    selectedUnit: {} as Unit | undefined,
    farmUnits: [] as Array<Unit>,

    // employees
    allEmployees: [] as Array<Employee>,
    selectedEmployee: {} as Employee,
  }),
  getters: {
    getFarmId(): string | undefined {
      const authStore = useAuthStore();
      return authStore.getFarmId;
    },
    isAdmin(): boolean | undefined {
      const authStore = useAuthStore();
      return authStore.isAdmin;
    },
    isOwner(): boolean | undefined {
      const authStore = useAuthStore();
      return authStore.isOwner;
    },
    isLeader(): boolean | undefined {
      const authStore = useAuthStore();
      return authStore.isLeader;
    },
    isEmployee(): boolean | undefined {
      const authStore = useAuthStore();
      return authStore.isEmployee;
    },
    hasPlanning(): boolean | undefined {
      const authStore = useAuthStore();
      return authStore.hasPlanning;
    },
    getUnit(state) {
      return (unitId: string) => {
        return state.farmUnits.find((u: Unit) => u.id === unitId);
      };
    },
    getAllowedUnits() {
      return (all: boolean) => {
        const authStore = useAuthStore();
        const self = this.allEmployees.find(
          (e) => e.uid === authStore.userToken?.uid,
        );

        return this.farmUnits.filter(
          (u: Unit) =>
            (u.unitName !== this.getSelectedUnit?.unitName || all) &&
            (self?.units.includes(u.id) || this.isOwner),
        );
      };
    },
    getSelectedUnit(state) {
      return state.selectedUnit;
    },
    getSelfEmployee(state) {
      const authStore = useAuthStore();
      return (
        state.allEmployees.find((e) => e.uid === authStore.userToken?.uid) ||
        ({} as Employee)
      );
    },
    getOwnerAsEmployee(state) {
      return (
        state.allEmployees.find((e) => e.uid === state.userFarm?.ownerId) ||
        ({} as Employee)
      );
    },
    getActiveUnitEmployees() {
      const unitId = this.getSelectedUnit?.id;
      if (this.showDataForAllUnits) {
        /* eslint-disable */
        // @ts-ignore
        const allActiveEmployees = this.allEmployees.filter(
          (e: any) => e.deleted === null || e.deleted === undefined
        );
        return allActiveEmployees;
      } else if (unitId) {
        /* eslint-disable */
        // @ts-ignore
        const allActiveEmployees = this.allEmployees.filter(
          (e:any) =>
            e.units.includes(unitId) &&
            (e.deleted === null || e.deleted === undefined)
        );
        return allActiveEmployees;
      } else {
        console.log("No unit id found");
      }
    },
    getDeletedUnitEmployees() {
      const unitId = this.getSelectedUnit?.id;
      if (unitId) {
        /* eslint-disable */
        // @ts-ignore
        const allDeletedEmployees = this.allEmployees.filter(
          (e:any) =>
            e.units.includes(unitId) &&
            e.deleted
        );
        return allDeletedEmployees;
      } else {
        console.log("No unit id found");
      }
    },
    getActiveNotThisUnitEmployees() {
      const unitId = this.getSelectedUnit?.id;
      if (unitId) {
        /* eslint-disable */
        // @ts-ignore
        const allActiveEmployees = this.allEmployees.filter(
          (e:any) =>
            !e.units.includes(unitId) &&
            (e.deleted === null || e.deleted === undefined)
        );
        return allActiveEmployees;
      } else {
        console.log("No unit id found");
        return []
      }
    },
    /* eslint-enable */
    getEmployeeName(state) {
      const { isMobile } = useSystemState();
      return (eid?: string, uid?: string) => {
        const emp = state.allEmployees.find(
          (e) => e.id === eid || e.uid === uid,
        );
        if (emp === undefined) {
          return "Ukendt";
        }
        if (isMobile) {
          return emp.firstName + " " + emp.lastName[0];
        } else return emp.firstName + " " + emp.lastName;
      };
    },
    getEmployeeWorkdaysInPeriod() {
      return (period: Interval) => {
        const SATURDAY = 6;
        const SUNDAY = 7;
        const days = period.splitBy({
          days: 1,
        }) as Interval[];
        return days.reduce((employeeWorkdays, subInt) => {
          const d = subInt.start;
          return d !== null && d.weekday !== SATURDAY && d.weekday !== SUNDAY
            ? employeeWorkdays + 1
            : employeeWorkdays;
        }, 0);
      };
    },
    getCalculatedDaysToHours(state) {
      return (e: Employee, selectedPeriod: Interval, days: number) => {
        const contracts = e.hours;

        if (!contracts || !state.userFarm) {
          return 0;
        }

        const contract = findLatestValidContractInPeriod(
          contracts,
          selectedPeriod,
        );

        const DEFAULT_WORK_DAYS_PER_WEEK = 5;

        return state.userFarm.workDaysPerWeek
          ? (contract.value / state.userFarm.workDaysPerWeek) * days
          : (contract.value / DEFAULT_WORK_DAYS_PER_WEEK) * days;
      };
    },
    getGoalTime(state) {
      return (e: Employee, selectedPeriod: Interval) => {
        const contracts = e.hours;
        const employeeStart = DateTime.fromJSDate(e.startDate.toDate()).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        if (
          !contracts ||
          employeeStart?.toMillis() >= selectedPeriod.end!.toMillis()
        ) {
          return 0;
        }

        const employeeDaysInPeriod = Interval.fromDateTimes(
          employeeStart,
          selectedPeriod.end!,
        );

        const employeeWorkdays = this.getEmployeeWorkdaysInPeriod(
          employeeDaysInPeriod,
        ) as number;

        const hasEmployeeStartedThisPeriod =
          employeeStart >= selectedPeriod.start! &&
          employeeStart <= selectedPeriod.end! &&
          selectedPeriod.start!.day !== employeeStart.day;

        const contract = findLatestValidContractInPeriod(
          contracts,
          selectedPeriod,
        );

        const DEFAULT_WORK_DAYS_PER_WEEK = 5;

        return hasEmployeeStartedThisPeriod
          ? (contract.value /
              (state.userFarm?.workDaysPerWeek || DEFAULT_WORK_DAYS_PER_WEEK)) *
              employeeWorkdays
          : (contract.value * 52) / 12;
      };
    },
  },
  actions: {
    syncUserWithDb(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.user = {} as UserProfile;
        const db = getFirestore();
        const authStore = useAuthStore();
        if (authStore.userToken) {
          logger.firebase(`Listening to user ${authStore.userToken.uid}`);
          const docRef = doc(db, "users", authStore.userToken.uid);
          userListenerConnection = onSnapshot(
            docRef,
            (docSnap) => {
              logger.firebase("User doc updated:", docSnap.data());
              if (docSnap.exists()) {
                const data = {
                  id: docSnap.id,
                  ...docSnap.data(),
                };
                this.user = data as UserProfile;
                resolve();
              } else {
                logger.warn("Your user could not be found");
                reject();
              }
            },
            (error) => logger.error(error),
          );
        }
      });
    },
    syncFarmWithDb(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.userFarm = null;
        navigator.serviceWorker.addEventListener("message", (event) => {
          if (event.data.msg === "sw-install") {
            logger.firebase("Disconnecting farm listener");
            this.disconnect();
          }
        });
        const db = getFirestore();

        if (this.getFarmId) {
          logger.firebase(`Listening to farm ${this.getFarmId}`);
          const docRef = doc(db, "farms", this.getFarmId);
          farmListenerConnection = onSnapshot(docRef, (docSnap) => {
            const data = {
              id: docSnap.id,
              ...docSnap.data(),
            };
            if (data.id) {
              this.userFarm = data as Farm;
              resolve();
            }
          });
        } else {
          logger.warn("You are not connected to a farm");
          reject();
        }
      });
    },
    syncUnitsWithDb(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.farmUnits = [];
        const db = getFirestore();

        if (this.getFarmId) {
          const unitsQueryRef = collection(
            db,
            "farms/" + this.getFarmId + "/units",
          );
          unitsListenerConnection = onSnapshot(
            unitsQueryRef,
            async (querySnap) => {
              logger.firebase(`Listening to units: ${querySnap.size}`);
              for (const docChange of querySnap.docChanges()) {
                const docReference = docChange.doc;
                const data = {
                  id: docReference.id,
                  ...docReference.data(),
                } as Unit;
                if (docChange.type === "added") {
                  this.farmUnits.push(data);
                }
                if (docChange.type === "modified") {
                  const idx = this.farmUnits.findIndex((u) => u.id === data.id);
                  this.farmUnits.splice(idx, 1, data);
                }
                if (docChange.type === "removed") {
                  const idx = this.farmUnits.findIndex((u) => u.id === data.id);
                  this.farmUnits.splice(idx, 1);
                }
              }
              if (this.farmUnits.length === querySnap.size) {
                resolve();
              }
            },
            (e) => logger.error(e),
          );
        } else {
          logger.warn("You are not connected to a farm");
          reject();
        }
      });
    },
    syncEmployeesWithDb(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.allEmployees = [];
        const db = getFirestore();

        if (this.getFarmId) {
          const employeesQueryRef = query(
            collection(db, "farms/" + this.getFarmId + "/employees"),
          );
          employeesListenerConnection = onSnapshot(
            employeesQueryRef,
            (querySnap) => {
              console.log(`Db listen: employees ${querySnap.size}`);
              querySnap.docChanges().forEach((docChange) => {
                const docReference = docChange.doc;
                const data = {
                  id: docReference.id,
                  ...docReference.data(),
                } as Employee;
                if (docChange.type === "added") {
                  this.allEmployees.push(data);
                }
                if (docChange.type === "modified") {
                  const idx = this.allEmployees.findIndex(
                    (e) => e.id === data.id,
                  );
                  this.allEmployees.splice(idx, 1, data);
                }
                if (docChange.type === "removed") {
                  const idx = this.allEmployees.findIndex(
                    (e) => e.id === data.id,
                  );
                  this.allEmployees.splice(idx, 1);
                }
              });
              if (this.allEmployees.length === querySnap.size) {
                resolve();
              }
            },
          );
        } else {
          console.log("You are not connected to a farm");
          reject();
        }
      });
    },
    disconnect() {
      if (userListenerConnection) userListenerConnection();
      if (farmListenerConnection) farmListenerConnection();
      if (unitsListenerConnection) unitsListenerConnection();
      if (employeesListenerConnection) employeesListenerConnection();
    },
    async getPermissions(): Promise<void> {
      const authStore = useAuthStore();
      return authStore.getPermissions();
    },
    // users
    update(data: DocumentData) {
      const { fsDocUpdate } = useFirestore();
      fsDocUpdate(this.user.id, data, "users");
    },
    // farm
    updateFarm(data: DocumentData): Promise<void> {
      return new Promise((resolve, reject) => {
        const { setSuccess, setError } = useErrorLog();
        const { fsDocUpdate } = useFirestore();
        if (this.userFarm) {
          fsDocUpdate(this.userFarm.id, data, "farms")
            .then(() => {
              resolve();
              setSuccess("Firmaoplysninger er ændret.");
            })
            .catch(() => {
              setError("Firmaoplysninger kunne ikke ændres.");
              reject();
            });
        }
      });
    },
    // units
    setSelectedUnit(unitId: string | undefined) {
      if (unitId) {
        this.selectedUnit = this.farmUnits.find((u: Unit) => u.id === unitId);
      } else {
        if (this.user.selectedUnitId) {
          this.selectedUnit = this.farmUnits.find(
            (u: Unit) => u.id === this.user.selectedUnitId,
          );
        } else {
          this.selectedUnit = this.farmUnits.find(
            (u: Unit) => u.unitName === this.user.selectedUnit,
          );
        }
      }
    },
    addUnit(data: DocumentData): Promise<void> {
      return new Promise((resolve, reject) => {
        const { fsFarmDocAdd } = useFirestore();

        fsFarmDocAdd(data, "units").then(resolve).catch(reject);
      });
    },
    updateUnit(id: string, data: DocumentData): Promise<void> {
      return new Promise((resolve, reject) => {
        const { setSuccess, setError } = useErrorLog();
        const { fsFarmDocUpdate } = useFirestore();

        fsFarmDocUpdate(id, data, "units")
          .then(() => {
            resolve();
            setSuccess("Driftsenheden er ændret.");
          })
          .catch(() => {
            setError("Driftsenheden kunne ikke ændres.");
            reject();
          });
      });
    },
    // Employee
    addEmployee(data: EmployeeData) {
      return new Promise((resolve, reject) => {
        const functions = getFunctions();
        const func = httpsCallable(functions, "employeeServiceCreate");
        func(data).then(resolve).catch(reject);
      });
    },
    updateEmployee(id: string, data: DocumentData): Promise<void> {
      return new Promise((resolve, reject) => {
        const { fsFarmDocUpdate } = useFirestore();

        return fsFarmDocUpdate(id, data, "employees")
          .then(resolve)
          .catch(reject);
      });
    },
    deleteEmployee(id: string): Promise<void> {
      return new Promise((resolve, reject) => {
        const emp = this.allEmployees.find((e: Employee) => e.id === id);
        if (emp === undefined) {
          reject("Employee was not found");
        } else {
          const now = new Date();
          const functions = getFunctions();
          const call = httpsCallable(functions, "v2ServiceDeleteEmployee");

          this.updateEmployee(emp.id, {
            deleted: Timestamp.fromDate(now),
            deletedBy: this.isAdmin ? "Administrator" : this.getSelfEmployee.id,
          })
            .then(() => {
              call({ uid: emp.uid })
                .then((res: any) => {
                  resolve();
                  console.log(res.data.code, res.data.message);
                })
                .catch(() => reject("Internal error. Contact support"));
            })
            .catch((e) => {
              console.log(e);
              reject("Error in updating employee. Contact support");
            });
        }
      });
    },
  },
});
