import { ref } from "vue";
import { useMainStore } from "../../../stores/main";
import { fbMessagingInit } from "./firebase-messaging";
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  orderBy,
  Timestamp,
  Unsubscribe,
  writeBatch,
  doc,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";

export interface NotificationDoc {
  id: string;
  title: string;
  body: string;
  link?: string;
  relatedUrl?: string;
  tag?: string;
  read: boolean;
  created: Timestamp;
}

const NOTIFICATIONS_LIMIT = 10;
const notifications = ref<NotificationDoc[]>([]);
const hasMoreNotifications = ref(true);
let lastVisible: any = null;
let listener: Unsubscribe;

export const useNotifications = () => {
  function initNotifications() {
    fbMessagingInit();
    const db = getFirestore();
    const mainStore = useMainStore();
    const queryRef = query(
      collection(db, "users/" + mainStore.user.id + "/notifications"),
      orderBy("created", "desc"),
      limit(NOTIFICATIONS_LIMIT)
    );
    
    listener = onSnapshot(queryRef, async (querySnap) => {
      console.log(`Db listen notifications: ${querySnap.size}`);
      notifications.value = [];
      lastVisible = querySnap.docs[querySnap.docs.length - 1];
      hasMoreNotifications.value = querySnap.size === NOTIFICATIONS_LIMIT;
      
      querySnap.forEach((doc) => {
        const data = {
          id: doc.id,
          ...doc.data(),
        } as NotificationDoc;
        notifications.value.push(data);
      });
    });
  }

  async function loadMoreNotifications() {
    if (!lastVisible || !hasMoreNotifications.value) return;

    const db = getFirestore();
    const mainStore = useMainStore();
    const nextQuery = query(
      collection(db, "users/" + mainStore.user.id + "/notifications"),
      orderBy("created", "desc"),
      startAfter(lastVisible),
      limit(NOTIFICATIONS_LIMIT)
    );

    const querySnap = await getDocs(nextQuery);
    lastVisible = querySnap.docs[querySnap.docs.length - 1];
    hasMoreNotifications.value = querySnap.size === NOTIFICATIONS_LIMIT;

    querySnap.forEach((doc) => {
      const data = {
        id: doc.id,
        ...doc.data(),
      } as NotificationDoc;
      notifications.value.push(data);
    });
  }

  async function setNotificationsAsRead() {
    const db = getFirestore();
    const mainStore = useMainStore();
    const batch = writeBatch(db);

    for (const notification of notifications.value.filter((n) => !n.read)) {
      const docRef = doc(
        db,
        `users/${mainStore.user.id}/notifications/${notification.id}`,
      );
      batch.update(docRef, { read: true });
    }

    batch.commit();
  }

  function getNotification(notificationId: string) {
    return notifications.value.find((n) => n.id === notificationId);
  }

  function notificationsDisconnect() {
    listener();
  }

  return {
    initNotifications,
    notifications,
    setNotificationsAsRead,
    getNotification,
    notificationsDisconnect,
    loadMoreNotifications,
    hasMoreNotifications,
  };
};
