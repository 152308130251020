import {
  getFirestore,
  addDoc,
  doc,
  updateDoc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";

import { useMainStore } from "../stores/main";
import { useAuthStore } from "../modules/auth/store/auth";

import type { DocumentData } from "firebase/firestore";
import { useErrorLog } from "./errorLog";

export const useFirestore = () => {
  const db = getFirestore();
  const mainStore = useMainStore();
  const authStore = useAuthStore();

  /**
   * Creates a document in the database
   * @param data The data the document should contain
   * @param collectionName Name of the collection inside the farm document that the document should be created in
   * @returns "Document added" if success or error on fail
   */
  const fsFarmDocAdd = (
    data: DocumentData,
    collectionName: string,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { errorGuard } = useErrorLog();
      errorGuard(
        () => {
          const dataCopy = Object.assign({}, data);
          dataCopy.created = serverTimestamp();
          dataCopy.createdByEid = mainStore.isAdmin
            ? mainStore.getOwnerAsEmployee.id
            : mainStore.getSelfEmployee.id;
          dataCopy.deleted = null;
          addDoc(
            collection(
              db,
              "farms/" + mainStore.getFarmId + "/" + collectionName,
            ),
            dataCopy,
          ).then(() => {
            resolve();
          });
        },
        reject,
        "firestore.ts",
        "fsFarmDocAdd",
      );
    });
  };

  /**
   * Creates a document in the database
   * @param data The data the document should contain
   * @param collection Name of the collection inside the farm document that the document should be created in
   * @returns "Document added" if success or error on fail
   */
  const fsDocAdd = (
    data: DocumentData,
    collectionName: string,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { errorGuard } = useErrorLog();
      errorGuard(
        () => {
          const dataCopy = Object.assign({}, data);
          dataCopy.created = serverTimestamp();
          dataCopy.createdByUid = mainStore.isAdmin
            ? mainStore.userFarm?.ownerId
            : authStore.userToken?.uid;
          dataCopy.deleted = null;
          addDoc(collection(db, collectionName), dataCopy).then(() => {
            resolve();
          });
        },
        reject,
        "firestore.ts",
        "fsDocAdd",
      );
    });
  };

  const fsDocUpdate = (
    id: string,
    data: DocumentData,
    collectionName: string,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { errorGuard } = useErrorLog();
      errorGuard(
        () => {
          data.updated = serverTimestamp();
          data.updatedByUid = mainStore.isAdmin
            ? mainStore.userFarm?.ownerId
            : authStore.userToken?.uid;
          const docRef = doc(db, collectionName, id);
          updateDoc(docRef, data).then(() => {
            resolve();
          });
        },
        reject,
        "firestore.ts",
        "fsDocUpdate",
      );
    });
  };

  const fsFarmDocUpdate = (
    id: string,
    data: DocumentData,
    collectionName: string,
    overwrite = false,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { errorGuard } = useErrorLog();
      errorGuard(
        () => {
          data.updated = serverTimestamp();
          data.updatedByEid = mainStore.isAdmin
            ? mainStore.getOwnerAsEmployee.id
            : mainStore.getSelfEmployee.id;
          const docRef = doc(
            db,
            "farms/" + mainStore.getFarmId + "/" + collectionName,
            id,
          );
          if (overwrite) {
            setDoc(docRef, data).then(() => {
              resolve();
            });
          } else {
            updateDoc(docRef, data).then(() => {
              resolve();
            });
          }
        },
        reject,
        "firestore.ts",
        "fsFarmDocUpdate",
      );
    });
  };

  const fsFarmDocDelete = (
    id: string,
    collectionName: string,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { errorGuard } = useErrorLog();
      errorGuard(
        () => {
          const data = {
            deleted: serverTimestamp(),
            deletedByEid: mainStore.isAdmin
              ? mainStore.getOwnerAsEmployee.id
              : mainStore.getSelfEmployee.id,
          };
          const docRef = doc(
            db,
            "farms/" + mainStore.getFarmId + "/" + collectionName,
            id,
          );
          updateDoc(docRef, data).then(() => {
            resolve();
          });
        },
        reject,
        "firestore.ts",
        "fsFarmDocDelete",
      );
    });
  };

  const fsFarmDocRestore = (
    id: string,
    collectionName: string,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { errorGuard } = useErrorLog();
      errorGuard(
        () => {
          const data = {
            deleted: null,
            deletedByEid: null,
          };
          const docRef = doc(
            db,
            "farms/" + mainStore.getFarmId + "/" + collectionName,
            id,
          );
          updateDoc(docRef, data).then(() => {
            resolve();
          });
        },
        reject,
        "firestore.ts",
        "fsFarmDocDelete",
      );
    });
  };

  return {
    fsFarmDocAdd,
    fsDocAdd,
    fsDocUpdate,
    fsFarmDocUpdate,
    fsFarmDocDelete,
    fsFarmDocRestore,
  };
};
