/**
 * Utility for handling logging in a production-safe way
 */

const isProduction = import.meta.env.MODE === 'production';

export const logger = {
  /**
   * Log general information
   * Only logs in non-production environments
   */
  info(...args: unknown[]) {
    if (!isProduction) {
      console.log(...args);
    }
  },

  /**
   * Log warnings
   * Only logs in non-production environments
   */
  warn(...args: unknown[]) {
    if (!isProduction) {
      console.warn(...args);
    }
  },

  /**
   * Log errors
   * Always logs in all environments as errors should be tracked
   */
  error(...args: unknown[]) {
    console.error(...args);
  },

  /**
   * Log Firebase/DB related information
   * Only logs in non-production environments
   */
  firebase(...args: unknown[]) {
    if (!isProduction) {
      console.log('[Firebase]', ...args);
    }
  }
};

export function useLogger() {
  return logger;
} 