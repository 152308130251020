import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuthStore } from "../store/auth";
import { useMainStore } from "../../../stores/main";
import { useSystemState } from "../../../composables/systemState";
import { useNotifications } from "../../notifications/utilities/notifications";
import { analyticsConnect } from "../../../plugins/analytics";
import { Ref } from "vue";

export function useAuth() {
  const authStore = useAuthStore();
  const mainStore = useMainStore();
  const { startProcessing, stopProcessing } = useSystemState();

  const initializeAuth = (loading: Ref<boolean>) => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      loading.value = true;
      startProcessing();
      try {
        if (user) {
          // Set auth store state
          authStore.userToken = user;
          authStore.email = user.email || "";
          authStore.isLoggedIn = true;
          console.log("You are authenticated");

          // Get permissions and sync data
          await authStore.getPermissions();

          analyticsConnect();

          // Sync data in sequence to ensure dependencies are met
          try {
            await mainStore.syncUserWithDb();
            console.log("User data synced");

            await mainStore.syncFarmWithDb();
            console.log("Farm data synced");

            await mainStore.syncUnitsWithDb();
            console.log("Units data synced");

            await mainStore.syncEmployeesWithDb();
            console.log("Employees data synced");

            mainStore.setSelectedUnit(undefined);
            loading.value = false;

            // Initialize notifications
            const { initNotifications } = useNotifications();
            initNotifications();
            stopProcessing();
          } catch (error) {
            console.error("Error syncing data:", error);
          }
        } else {
          // Reset auth state
          authStore.isLoggedIn = false;
          authStore.userToken = null;
          authStore.permissions = {};
          console.log("You are not authenticated");
        }
      } catch (error) {
        console.error("Error during auth initialization:", error);
      } finally {
        stopProcessing();
        loading.value = false;
      }
    });
  };

  return {
    initializeAuth,
  };
}
